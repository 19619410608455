import React, { useContext, useState } from "react";
import Context from "../context/Context";
import "../Styles/FigmaStyle.css";


export default function ControllerHeader(props){
    const {textColor, ControllerName, fontSize, height, useBkg } = props;
    const context = useContext(Context);

    const bkg = {
        background: useBkg ? 'rgba(0,0,0, .725)' : 'rgba(0,0,0,0)',
        boxShadow: useBkg ? '0px 2px 2px rgba(0,0,0, 1)' : '0 0 0 rgba(0,0,0,0)'
    }

    //NOTE you need to make sure you have a head-Reference div class as the parent div of this in order for it to be positioned correctly
    return(
        <div className="head-reference" id="ControllerHeader" style={{height: `${height}`, lineHeight: `${height}`, ...bkg }}>
            <span className="controllerName" style={{color: textColor, fontSize: fontSize}}>{ControllerName} </span>
          
        </div>
    );
}

export function TeamTurnHeader(props){
    const{Team, myTurn} = props;

    return(
        <div style={{marginTop: '8px', marginBottom: '4px'}}>
            <ControllerHeader ControllerName={Team.name} height={'4vh'} fontSize={'24pt'} textColor={Team.color}/>
            { myTurn !== null ? <ControllerHeader ControllerName={myTurn ? 'My Turn' : 'Another Player Turn' } height={'4vh'} fontSize={'12pt'} textColor={myTurn ? 'white' : 'rgba(.55, .55, .55, .575)'}/> : <></>}
        </div>
    );
}