import React, { useEffect, useContext, useRef } from "react";
import websocket from "../helpers/WebSocket";
import Context from "../context/Context";
import {useNetworkingHooks} from "../helpers/NetworkMessaging";
import {CoreGameConnectionsHooks} from "../helpers/CoreGameConnections_Hooks";
import { useGameState, useTeamSelectionData } from "../helpers/TeamSelectionHooks";

export default function CristiansController(props){
    
    const context = useContext(Context);

    const useTeamSelectionDataContext = useTeamSelectionData();
    const TeamsRef = useRef();
    TeamsRef.current = useTeamSelectionDataContext.Teams;
    
    const useGameStatecontext = useGameState();
    const GameStateRef = useRef();
    GameStateRef.current = useGameStatecontext.GameState;
    const {SendSpecificMessageToServer, JoinWaitingRoom, Disconnect} = useNetworkingHooks(context.user);
    const {CheckCoreGameConnectionMessages} = CoreGameConnectionsHooks(context.user, useGameStatecontext, useTeamSelectionDataContext);

    useEffect(() => {
        //OnMount Things happen here
        document.title = "Cristian's Controller";

        websocket.AddListener(onPayload);
        window.addEventListener('beforeunload', handleTabClosing);
        
        JoinWaitingRoom();

        //SendMessageToServer();
        document.addEventListener("touchmove", preventBehavior, {passive: false});
        
        return () =>{
            //Unmount things happen here
            websocket.RemoveListener(onPayload);
            window.removeEventListener('beforeunload', handleTabClosing);
            document.removeEventListener("touchmove", preventBehavior, {passive: false});
        }
    }, []);

    const handleTabClosing = () => {
        Disconnect();
    }

    function onPayload(id, payload){
        
        console.log(`Payload message recieved ${id} ${payload}`)
        CheckCoreGameConnectionMessages(id, payload);

        //Game specific calls
        switch(id){
            case "ShuffleboardGameSetup":
                SetupShuffleboardController(payload);
                break;

            case "HockeyGameSetup":
                SetupHockeyController(payload);
                break;

            default:
                break;
        }
    }

    function SetupShuffleboardController(payload)
    {
        //useTeamSelectionDataContext.SetTeam_Home({color: payload.homeTeam.color.substring(0, payload.homeTeam.color.length - 2), name: payload.homeTeam.name});
        //useTeamSelectionDataContext.SetTeam_Away({color: payload.awayTeam.color.substring(0, payload.awayTeam.color.length - 2), name: payload.awayTeam.name});
    }

    function SetupHockeyController(payload)
    {
        //useTeamSelectionDataContext.SetTeam_Home({color: payload.homeTeam.color.substring(0, payload.homeTeam.color.length - 2), name: payload.homeTeam.name});
       // useTeamSelectionDataContext.SetTeam_Away({color: payload.awayTeam.color.substring(0, payload.awayTeam.color.length - 2), name: payload.awayTeam.name});
    }
    
    function preventBehavior(e) {
        e.preventDefault(); 
    };

    function TrackingPosition(payload){
        SendSpecificMessageToServer("Shuffleboard_Aim", payload);
    }

    function TrackingEnded(coords){
        
        var msg = {
            coords: coords,
            slider: 50
        }

        SendSpecificMessageToServer("Shuffleboard_Fire", msg);
    }

    function UpdateSliderValue(value){
        //setSliderValue(value);
    }

    return (
        <div style={{width: '100%', height: '100%'}}> Hellow World </div>
    );
}
