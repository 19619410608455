import React from "react";
import './App.css';
import websocket from "./helpers/WebSocket";
import { BrowserRouter, Route, Routes,  Link } from "react-router-dom";
import GameContextProvider from './context/GameContextProvider'
import LandingPageClass from "./LandingPage";
import Hockey from "./gamecontrollers/hockeyController";
import Soccer from "./gamecontrollers/soccerController";
import PaperFootball from "./gamecontrollers/paperFootballController";
import SlotCar from "./gamecontrollers/SlotCarController";
import ShuffleBoard from "./gamecontrollers/shuffleBoardController";
import Sumo from "./gamecontrollers/sumoController_new";
import Lobby from "./gamecontrollers/Lobby";
import CristiansController from "./gamecontrollers/cristiansController";

export default function App() {
  console.log("App.js things happen here");
  return (
    
    <div style={{ height: '100vh', width: '100vw'}}>
    <GameContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/Lobby" element={<Lobby/>} exact/>
            <Route path="/Hockey" element={<Hockey/>} exact/>
            <Route path="/Soccer" element={<Soccer/>} exact/>
            <Route path="/Sumo" element={<Sumo/>} exact/>
            <Route path="/PaperFootball" element={<PaperFootball/>} exact/>
            <Route path="/SlotCar" element={<SlotCar/>} exact/>
            <Route path="/ShuffleBoard" element={<ShuffleBoard/>} exact/>
            <Route path="/Cristian" element={<CristiansController exact/>} />
            <Route path="/" element={<LandingPageClass exact/>} />
          </Routes>
        </BrowserRouter>
    </GameContextProvider>
    </div>
  
  );  
}


/*
<BrowserRouter>
      <Routes>
        <Route path="/" component={LandingPageClass} exact/>
        <Route path="/CarRace" component={CarRace} exact/>
        </Routes>
    </BrowserRouter>
*/


/*
 <nav>
        <div>
          <Link to="/">Home</Link>
        </div>
        <div>
          <Link to="/user/:id">User</Link>
        </div>
      </nav>
*/

/*
        <Route path="/CarRace/:id" component={CarRace} />

*/


