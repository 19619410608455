import React, { useEffect, useContext, useState, useMemo, useRef } from "react";  
import websocket from "../helpers/WebSocket";
import Context from "../context/Context";
import {useNetworkingHooks} from "../helpers/NetworkMessaging";
import { useGameState, useTeamSelectionData } from "../helpers/TeamSelectionHooks";
import { soccerTouchArea, catapultReticle, soccerBkgSplash, soccerLogo } from "../Sprites/ImagesLoader";
import {CoreGameConnectionsHooks} from "../helpers/CoreGameConnections_Hooks";
import { PageBackground, PlayerBanner } from "../Components/PagePrefabs";
import { TeamSelection } from "../Components/TeamSelectionPrefabs";
import { GameplayArea } from "../Components/GameplayPrefabs";


export default function Soccer(props){
    const context = useContext(Context);

    const useTeamSelectionDataContext = useTeamSelectionData();
    const TeamsRef = useRef();
    TeamsRef.current = useTeamSelectionDataContext.Teams;
    
    const useGameStatecontext = useGameState();
    const GameStateRef = useRef();
    GameStateRef.current = useGameStatecontext.GameState;

    const {SendSpecificMessageToServer, JoinWaitingRoom, Disconnect} = useNetworkingHooks(context.user);
    const {CheckCoreGameConnectionMessages} = CoreGameConnectionsHooks(context.user, useGameStatecontext, useTeamSelectionDataContext);

    const MyTeam = useMemo(() => {return useTeamSelectionDataContext.Teams.myteam}, [useTeamSelectionDataContext]);
    const OtherTeam = useMemo(() => {return useTeamSelectionDataContext.Teams.otherteam}, [useTeamSelectionDataContext]);
    const Spectating = useMemo(() => {return useTeamSelectionDataContext.Teams.myteam === useTeamSelectionDataContext.Teams.neutralteam }, [useTeamSelectionDataContext]);
    

    useEffect(() => {
        //OnMount Things happen here
        document.title = "Soccer Controller";

        websocket.AddListener(onPayload);
        window.addEventListener('beforeunload', handleTabClosing);
        console.log(context.user);
        JoinWaitingRoom();
        //SendMessageToServer();
        document.addEventListener("touchmove", preventBehavior, {passive: false});

        console.log(context.user.playerID);
        return () =>{
            //Unmount things happen here
            websocket.RemoveListener(onPayload);
            window.removeEventListener('beforeunload', handleTabClosing);
            document.removeEventListener("touchmove", preventBehavior, {passive: false});
        }
    }, []);

    const handleTabClosing = () => {
        Disconnect();
    }

   
    function onPayload(id, payload){
        console.log(`Payload message recieved ${id} ${payload}`)
        CheckCoreGameConnectionMessages(id, payload);

        //Game specific calls
        switch(id){
           
            case "TeamSetup":
                SetupHockeyController(payload);
                break;
            default:
                break;
        }
    }

    function SetupHockeyController(payload)
    {
        console.log('SetUpHockey:', payload);
        
        useTeamSelectionDataContext.SetTeam_Home({color: payload.homeTeam.color.substring(0, payload.homeTeam.color.length - 2), name: payload.homeTeam.name});
        useTeamSelectionDataContext.SetTeam_Away({color: payload.awayTeam.color.substring(0, payload.awayTeam.color.length - 2), name: payload.awayTeam.name});
    }

    function preventBehavior(e) {
        e.preventDefault(); 
    };

    function TrackingPosition(payload){
        SendSpecificMessageToServer("AirHockey_MovePaddle", payload);
    }

    function TrackingEnded(){
        SendSpecificMessageToServer("Hockey_FirePuck");
    }

    const TouchInfo = {
        bkg: soccerTouchArea,
        reticule: catapultReticle,
        sendArray: false,
        rate: 15,
        onUpdateTracking: TrackingPosition,
        onTrackingEnd: TrackingEnded

    }

    return (

    <>
        <PageBackground bkgImage={soccerBkgSplash} bkgStyle={{filter: 'blur(.125rem)'}} useBlur useLogo={!GameStateRef.current.joinedGame} Logo={soccerLogo}/>
        <PlayerBanner myColor={MyTeam.color}/>
        {!GameStateRef.current.teamsSelected ? 
            <TeamSelection TeamContext={useTeamSelectionDataContext} GameState={GameStateRef.current} smts={(msg) => SendSpecificMessageToServer(msg)} /> 
            : 
            <GameplayArea game="Soccer" Team={MyTeam} OpposingTeam={OtherTeam} TouchBoxInfo={TouchInfo} Spectating={Spectating} Logo={soccerLogo} />    
        }

    </>
       
    );
}
