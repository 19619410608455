import React, { useEffect, useState, useContext } from "react"; 
import websocket from "./helpers/WebSocket.js";
import { useNavigate } from "react-router-dom";
import Context from "./context/Context.js";
import InputName from "./Components/InputToUpdateName.js";
import "./Styles/FigmaStyle.css";
import { useNetworkingHooks } from "./helpers/NetworkMessaging.js";
import { editNameButton, fordLogo, waitingRoomReferenceImage, popUpBox, nameEntryBox } from "./Sprites/ImagesLoader.js";
import ControllerHeader from "./Components/ControllerHeader.js";
import {GetUserData} from "./helpers/ServerCommunication.js";

export default function LandingPageClass(props){

    const navigate = useNavigate();
    const context = useContext(Context);
    const {SendSpecificMessageToServer, JoinWaitingRoom, Disconnect} = useNetworkingHooks(context.user);
    
    const[debugMessage, setDebugMesage] = useState("");
    const[OpenSetNameMenu, setOpenSetNameMenuBool] = useState(false);

    //const query =  new URLSearchParams(window.location.search);
    //const SessionIdRoom = query.get('SessionId');

    useEffect(() => {
        console.log("UseEffect things happening");
        window.addEventListener('beforeunload', handleTabClosing);
        //window.addEventListener('onbeforeunload', handleTabClosing);
        //document.addEventListener('onbeforeunload', handleTabClosing);
        window.addEventListener('unload', handleTabClosing);
        //window.addEventListener('pagehide', handleTabClosing3);
      

        //OnMount Things happen here
        _triggerOnMountThings();
        return () =>{
            //Unmount things happen here
            window.removeEventListener('beforeunload', handleTabClosing);
            window.removeEventListener('unload', handleTabClosing);
            //window.removeEventListener('pagehide', handleTabClosing3);
           // window.removeEventListener('onbeforeunload', handleTabClosing);


        }
    }, []);  
    
    const handleTabClosing = () => {
        console.log("beforeunload called");

        SendSpecificMessageToServer("beforeunloadEvent", "beforeunloaceventcalled");
    }
    const handleTabClosing2 = () => {
        console.log("blur called");

        SendSpecificMessageToServer("blurEvent", "blurcalled");

        //SendDisconnectMessage(context.user);
    }
    const handleTabClosing3 = () => {
        console.log("pagehide called");

        SendSpecificMessageToServer("pagehideEvent", "pagehidecalled");

        //SendDisconnectMessage(context.user);
    }
   

    function _triggerOnMountThings(){
        document.title = "Player Waiting Room";
        
        console.log("I am in Landing page");
        
        websocket.AddListener(_MessageDecipherer);

        GetUserData(context.user.playerId);
        JoinWaitingRoom(context.user);
       
    }
  
    function _OnClick_CarRaceController(){
        //window.location = '/CarRace';
        navigate("/CarRace", {id:  context.user.playerId});

    }

    function _MessageDecipherer(id, payload){
        console.log("I have recieved a message");
        console.log(id);
        //console.log(payload);
        switch(id){
            case 'GoToMarbles':
                setDebugMesage("GoToMarbles Message");
                if(payload.playerId === context.user.playerId){
                   navigate("/MarbleRace");
                }
                //console.log("Marbles was recieved as the message from the unity app.");
                //console.log("Player id deciphered is: " + payload.playerId);
                //window.location = `/CarRace?playerId=${payload.playerId}`;
                break;
            case 'GoToCatapult':
                setDebugMesage("GoToCatapult Message");
                if(payload.playerId === context.user.playerId){
                    navigate("/Catapult");
                }
                break;
            case 'GoToTinygolf':
                //console.log("I have recieved the go to tiny golf endpoint. Should be going now. ");
                setDebugMesage("GoToTinygolf Message");
                if(payload.playerId === context.user.playerId){
                    navigate("/Tinygolf");
                }
                break;
            case 'GoToTavernMatch':
                if(payload.playerId === context.user.playerId){
                    navigate("/TavernMatch");
                }
                break;
            case 'GoToFrogger':
                if(payload.playerId === context.user.playerId){
                    navigate("/Frog");
                }
                break;
            case 'GoToInfRunner':
                if(payload.playerId === context.user.playerId){
                    navigate("/InfRunner");
                }
                break;
            case 'SessionIdCommunication':
                /*RIP THIS OUT AND USE QR CODE QUERY FOR SESSSION ID LATER*/
                if(payload.playerId === context.user.playerId){
                    context.set('sessionId', payload.sessionId);
                }
                break;
            case 'GameDisconnectionMessageToUser':
                if(payload.playerId == context.user.playerId){
                    alert('You have been disconnected from the game');

                }                break;
            default:
                break;
        }
    }
    function _OnClick_AccessEditNameMenu(){
        console.log("I recieved the click");
        setOpenSetNameMenuBool(true);
    }
    function _OnClick_AcceptNewName(){
        console.log("I accepted the new name");

    }
    function _FlipBool(){
        setOpenSetNameMenuBool(false);
    }
    function _DoServerCallCheck(){
        try{
            GetUserData(context.user.playerId);
        }catch(error){
            console.error(error);
        }

    }
/*
    function SendSpecificMessageToServer(Id, Payload){
        var msg = {
            id: Id,
            payload: Payload
        }
        console.log("Trying to send message here");
        console.log(msg);
        websocket.Send(msg);
    }
*/
//Using grid in the next section, this requires extra <Div/> in order to space it out
    return(
        <div className="background" id="LandingPageBackgroundColor">
            <ControllerHeader/>
            <div className="FullScreen" >
                <div className={"fordLogoContainer"}> 
                    <img src={fordLogo} className={"fordLogoImageInContainer"}/>
                    <br/>
                    <span className="fordGamingTextInContainer">FordGaming</span>
                </div>
                <div className={"playerGreetingContainer"}> 
                    <span className="playerGreeting_Hello">Hello,</span>
                    <br/>
                    <span className="playerGreeting_PlayerName">{context.user.playerName}</span>
                </div>
                <div className="editNameButtonContainer" onClick={_OnClick_AccessEditNameMenu}>
                    <img src ={editNameButton} className={"EditNameButtonImage2"}/>
                    <span className="EditTextNameOnButton2">Edit Name</span>
                </div>
                <div className="waitingRoomTextContainer">
                    <span className="waitingRoomText">Waiting Room</span>
                    
                    <p className="selectGameText">
                        Please select a game <br/>
                        from the IVI.
                     </p>
                </div>
                <div className="DebugContainer">
                    <span className="Debug_buttomPlayerID">{context.user.playerId}</span>
                </div>
            </div>
            {OpenSetNameMenu ? <InputName  closeMenu={_FlipBool}/> : <></>}

               

        </div>

    );
    
  

}

//<button className="debugButton" onClick={_DoServerCallCheck}>ServerCheckDebug</button>
//<span> PlayerId: {context.user.playerId}</span>
// <button className="debugButton" onClick={_DoServerCallCheck}>ServerCheckDebug</button>

//                <img className="ReferenceImage" src={waitingRoomReferenceImage}/>

//            <img className="ReferenceImage" src={waitingRoomReferenceImage}/>



/*
 <div>
                 <div>
                    <div className="nameChangeOverlay_tintBlue"></div>
                    <div className="nameChangeOverlay_tintBlack"></div>
                    
                </div>
                <div className="popupBoxContainer">
                    <img src={popUpBox} className= "popUpBox"></img>
                    <img src={nameEntryBox} className="nameEntryBox"></img>
                    <span className="EnterNameText_InPopup">Please enter your name</span>

                    <img src ={editNameButton} className={"EditNameButtonImage2_InPopup"}/>
                    <span className="EditTextNameOnButton2_InPopup">Accept</span>
                    <input type={"text"} className="inputClass_InPopup"/>
                </div>

            </div>



*/
/* Remaking, here is what was before 
            <div className="MarginSpace-TopBarToLogo"></div>
            <div className="section">
                <img src={fordLogo} className={"fordLogoImage"}/>
            </div>
            <div className="fordGaming">
                FordGaming
            </div>
            <div className="MarginSpace-LogoToName"> </div>
            <div className="NormalWhiteText_FordFont">
                Hello,
            </div>
            <div className="BoldWhiteText_FordFont">
                 {context.user.playerName}
            </div>
            <div className="head-text">
                <div>
                    <img src ={editNameButton} className={"EditNameButtonImage"} />
                </div>
                <div className="textOnEditButton">
                    Edit Name
                </div>
            </div>
            <div className="Debug_buttomPlayerID">
                {context.user.playerId}
            </div>
           
            <InputName/>
            <p>
                {debugMessage}
            </p>



*/









//            <button onClick={_OnClick_CarRaceController}>Car Race Controller</button>

/*
export default class LandingPageClass extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            playerId: -1
        }
    }

    componentDidMount(){
        document.title = "Player Waiting Room";
        console.log("I am in Landing page");
        websocket.AddListener(this._MessageDecipherer);

        if(this.props.id === undefined && this.state.playerId === -1){
           
            console.log("unique id is null or undefined, need to create a new unique for this player: " + this.props.id);

            let uniqueID = GenerateUniqueID();
            this.setState({playerId: uniqueID}, () =>{
                let payload = {
                    id: this.state.playerId
                };
                this.SendSpecificMessageToServer("JoinWaitingRoom", payload);
            })
            
        }else{
            let payload = {
                id: this.state.playerId
            };
            this.SendSpecificMessageToServer("JoinWaitingRoom", payload);
        }

      
    }
    _SendUnjoinMessage = () =>{
        console.log("Leaving the page, letting the waiting room list know that i am leaving it");

        let payload = {
            id: this.state.playerId
        };
        this.SendSpecificMessageToServer("LeaveWaitingRoom", payload);
    }
    _OnClick_CarRaceController = () => {
        this._SendUnjoinMessage();
        //window.location = '/CarRace';
        this.props.navigate("/CarRace");

    }

    _MessageDecipherer = (id, payload) => {
        console.log("I have recieved a message");
        //console.log(id);
        //console.log(payload);
        switch(id){
            case 'GoToMarbles':
                console.log("Marbles was recieved as the message from the unity app.");
                console.log("Player id deciphered is: " + payload.playerId);
                //window.location = `/CarRace?playerId=${payload.playerId}`;
                this.props.navigate("/CarRace");
                break;
            default:
        }
    }

    render(){
        return(
            <div>
                <h1>
                    Hello world
                </h1>
                    <button onClick={this._OnClick_CarRaceController}>Car Race Controller</button>

            </div>
        );
    }
    SendSpecificMessageToServer = (Id, Payload) =>{
        var msg = {
            id: Id,
            payload: Payload
        }
        websocket.Send(msg);
    }

}

*/


//        window.location = '/QRScan';


 /*
            Promise.resolve()
            .then(() => { setPlayerId({uniqueID}); console.log(playerId)})
            .then( () => { 

                console.log(playerId);
                let payload = {
                    id: playerId
                };
               // SendSpecificMessageToServer("JoinWaitingRoom", payload);
            })
            */