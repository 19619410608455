
import { loadImage } from "../helpers/dom"

import Gremlin_01 from "../Sprites/PlayerIcons/Gremlin01.png";

import TeamRed from "../Sprites/TeamIcons/TeamRed.png";
import TeamOrange from "../Sprites/TeamIcons/TeamOrange.png";
import TeamYellow from "../Sprites/TeamIcons/TeamYellow.png";
import TeamGreen from "../Sprites/TeamIcons/TeamGreen.png";
import TeamBlue from "../Sprites/TeamIcons/TeamBlue.png";
import TeamPurple from "../Sprites/TeamIcons/TeamPurple.png";
import TeamWhite from "../Sprites/TeamIcons/teamWhite.png";


export const preloadImages = async () => {
    await Promise.all(
      [
        Gremlin_01,
        TeamRed,
        TeamOrange,
        TeamYellow,
        TeamGreen,
        TeamBlue,
        TeamPurple,
        TeamWhite
        
      ].map(loadImage),
    )
  
    void Promise.all(
      [
        Gremlin_01,
        TeamRed,
        TeamOrange,
        TeamYellow,
        TeamGreen,
        TeamBlue,
        TeamPurple,
        TeamWhite
        
      ].map(loadImage),


    )
  }
  
  export {    
      Gremlin_01,
      TeamRed,
      TeamOrange,
      TeamYellow,
      TeamGreen,
      TeamBlue,
      TeamPurple,
      TeamWhite 
  }

export const GetPlayerIcon = (index) =>{
  let i = 0;

  if(index > 2) i = 2;
  else i = index;
  
  switch(i){
      case 0: return Gremlin_01;
      case 1: return TeamGreen;
      case 2: return TeamWhite;
  }
}

export const GetTeamIcon = (teamName) =>{
  switch(teamName.toLowerCase()){
      case 'blaze': return TeamRed;
      case 'anglers': return TeamOrange;
      case 'brimstone': return TeamYellow;
      case 'overlords': return TeamGreen;
      case 'typhoons': return TeamBlue;
      case 'wardens': return TeamPurple;
      case 'wraiths': return TeamWhite;
      case 'meteors': return TeamWhite;
      case 'buzzers': return TeamPurple;
  }

  return null;
}


  
