import React, { useEffect, useContext, useMemo, useState, useRef } from "react";  
import Context from "../context/Context";

import "../Styles/TinyGolfStyle.css";
import {SendSpecificMessageToServer } from "../helpers/NetworkMessaging";
import { TextBox } from "./PagePrefabs";


export default function TouchFingerTracker(props){
    const {game, bkgImg, reticuleImg, sendArray, onUpdateTracking, onTrackingEnd, intervalRate} = props;
    const context = useContext(Context);
    const[currentlyClicked, setCurrentlyClicked] = useState(false);
    const currClickedRef = useRef();
    currClickedRef.current = currentlyClicked;
    
    const[TrackCoords, setTrackCoords] = useState({x:0, y:0});
    const[NormalizedCoords, setNormalizedCoords] = useState({x:0, y:0});
    const[NormalizedCoordsArray, setNormalizedArray] = useState([]);
    const NormRef = useRef();
    NormRef.current = NormalizedCoords;

    const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

    


    useEffect(() => {
        document.getElementById('TouchRect').setAttribute('draggable', false);
        
        setInterval(() => {
            IntervalSender();
            
        }, intervalRate ?? 75);

        return () =>{
            clearInterval(IntervalSender)
        }
    }, []);

    function CalculateTrackedPositionTouch(evt){
        if(evt.targetTouches != null){
            var rect = evt.target.getBoundingClientRect();
            var Bounds = document.getElementById("TouchRect").getBoundingClientRect();
    
            let coords = {
                x: clamp(evt.targetTouches[0].pageX - rect.left, 0, Bounds.width),
                y: clamp(evt.targetTouches[0].pageY - rect.top, 0, Bounds.height)
            
            }
            setTrackCoords(coords);
            
            //send floats to game for aiming.
            let nCoords = {
                x: clamp(coords.x / (Bounds.width - (Bounds.width / 15)), 0, 1),
                y: clamp(coords.y / (Bounds.height - (Bounds.height / 15)), 0 , 1)
            }
    
            nCoords.x = Math.round(nCoords.x * 1000) / 1000;
            nCoords.y = Math.round(nCoords.y * 1000) / 1000;
      
            if(props.Invert){
                nCoords.x = (1 - nCoords.x);
            }
    
            setNormalizedCoords(nCoords);
            if(sendArray) UpdateNormalizedArray(nCoords);
        }
        
    }

    function UpdateNormalizedArray(coord){   
        let nArray = [...NormalizedCoordsArray];

        if(nArray.length > 99){
            
            nArray.shift();
            nArray.push(coord);

            setNormalizedArray(nArray);
        }
        else{
            setNormalizedArray(NormalizedCoordsArray => [...NormalizedCoordsArray, coord]);
        } 

        
    }

    function TouchStarted(event){
        console.log("Touch-Start");
        if(sendArray) setNormalizedArray([]);
        CalculateTrackedPositionTouch(event);
        setCurrentlyClicked(true);
    }

    function TrackFinger_TouchEvent(event){
        if(currentlyClicked){
            CalculateTrackedPositionTouch(event);
        }
    }

    function TouchEndForFire(){
        console.log("Mouse Up occurred");
        setCurrentlyClicked(false);
        
        onTrackingEnd(NormalizedCoordsArray)

        if(sendArray)
        {
            setNormalizedArray([]);
        }

    }

    function IntervalSender(){
        if(currClickedRef.current){
            //console.log("Float values X, Y: " + NormRef.current.x + ", " + NormRef.current.y);
            var payload = {
                
                xFloat: NormRef.current.x,
                yFloat: NormRef.current.y
            }
            //console.log(payload);
            onUpdateTracking(payload);
        }
    }

    const CornerRadius = useMemo(() => {
        switch(game){
            case 'Hockey': return '32px';
            case 'Shuffleboard': return '12px';
            default: return '16px;'
        }});

    const TouchAreaContainer = {
        position: 'relative',
        margin: '0 auto',
        aspectRatio: '1 / 1',
        top: '0',
        width: '87.5%',
        borderRadius: CornerRadius,
        boxShadow:' 0 8px 4px rgba(0, 0, 0, 0.2), inset  8px 8px 40px 20px rgba(255, 235, 255, 0.125)',
        backgroundSize: '100% 100%',
        outline: 'solid 4px #FFD600' ,
        outlineOffset: '4px',
    }

    const TouchInnerArea = {
        position: 'absolute',
        left: '1%',
        top: '1%',
        width: '98%',
        height: '98%'
    }

    const ReticulePosition = {
        position: 'relative',
        width: '25%',
        height: '25%',
        top: '0%',
        left: '0%',
        pointerEvents: 'none'
    }

    const TouchReticule = (game, image) => {
        switch(game){
            
            case 'Hockey': return ImageReticule(image);
            case 'Shuffleboard': return SimpleReticule();
        }

    }

    const ImageReticule = (reticuleImg) => {
        const imgStyle={
            position: 'relative',
            width: '100%',
            top: '-50%',
            left: '-50%',
            pointerEvents: 'none'
        }
    
        return (<img src={reticuleImg} id="reticle" style={{...imgStyle}} />);
    }
    
    const SimpleReticule = () => {
        const imgStyle={
            display: 'flex',
            flex: 'row nowrap',
            position: 'relative',
            width: '100%',
            aspectRatio: '1 / 1',
            top: '-50%',
            left: '-50%',
            pointerEvents: 'none',
            borderRadius: '50%',
            border: '4px solid white',
            backgroundColor: 'rgba(255, 255, 255, .375)',
            justifyContent: 'center'
        }
    
        const innerImgStyle={
            display: 'flex',
            position: 'relative',
            margin: 'auto auto',
            width: '20%',
            height: '20%',
            pointerEvents: 'none',
            borderRadius: '50%',
            border: '4px solid white',
            backgroundColor: 'rgba(255, 255, 255, 1)'
        }
    
        return (<div id="reticle" style={{...imgStyle}} ><div style={{...innerImgStyle}} /></div>);
    }

    return (
        
    <div id="TouchAreaContainer" style={{background: `url(${bkgImg})`, ...TouchAreaContainer}}>
        <div id="TouchRect" style={{...TouchInnerArea}}
            onTouchMove={TrackFinger_TouchEvent} 
            onTouchStart={TouchStarted} 
            onTouchEnd={TouchEndForFire}
        >                
            
            {currentlyClicked != null ? 
                currentlyClicked ? 
                <div id="reticlePosition" style={{...ReticulePosition, left: TrackCoords.x, top: TrackCoords.y}}>
                    {TouchReticule(game, reticuleImg)}
                </div> : <></> : 
            <></> }
            
        </div>
    </div>
       
    );
}



export function TouchSlider(props){
    const { GetSliderValue } = props;
    const [SliderPos, setSliderPos] = useState('50')
    
    const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

    const UpdateSliderPos = (event) => {
        if(event.targetTouches != null){

            var rect = event.target.getBoundingClientRect();
            var Bounds = document.getElementById("TouchSlider").getBoundingClientRect();
            let x = clamp(event.targetTouches[0].pageX - rect.left, 0, Bounds.width);
            let nX = Math.round( clamp(x / (Bounds.width - (Bounds.width / 15)), 0, 1) * 10);
            let rX = Math.round(nX * 10);
            
            setSliderPos(rX);
            ReturnSliderValue(rX);
        }
    }

    const ReturnSliderValue = (value) => {
        GetSliderValue(value);
    }

    const TouchSliderContainer = {
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        background: "linear-gradient(to bottom, rgba(100, 100, 100, .9), rgba(75, 75, 75, .9))",
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.375), inset  8px 8px 40px 20px rgba(255, 244, 171, 0.2)',
        borderRadius: '12px'
    }

    const TouchSliderInternals ={
        display: 'flex',
        flexFlow: 'row nowrap',
        flexGrow: '0',
        justifyContent: 'space-between',
        margin: '0 auto',
        position: 'relative',
        width: '92%',
        height: '100%',
    }
    
    const SliderDelta = useMemo(() => {return SliderPos > 50 ? 100 - SliderPos : SliderPos}, [SliderPos])
    const leftSpin = useMemo(() => {return SliderPos <= 50}, [SliderPos])

    const TouchSliderGlow = {
        position: 'absolute',
        top: '0',
        left: leftSpin ? '' : '45%',
        right: leftSpin ? '45%' : '',
        background: `linear-gradient(to ${leftSpin ? 'left' :'right'}, #FFD600 37.5%, ${'orange'})`,
        height: '100%',
        width: SliderDelta == 50 ? '0' :`calc(55% - ${SliderDelta}%)`,
        borderRadius: '12px',
    }    

    return(
        <div style={{poisition: 'relative', width: '100%', height: '90px', marginTop: '8px'}}>
            <div style={{position: 'relative', width: '90%', height: '40%', margin: 'auto auto', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between'}}> 
                <div style={{display: 'flex', height: '100%', width: '30%'}}>
                    <TextBox width={'100%'} fontSize={"x-large"} textAlign={"left"} text={"Left"} />
                </div>
                <div style={{display: 'flex', height: '100%', width: '30%'}}>
                    <TextBox width={'100%'} fontSize={"xx-large"} textAlign={"center"} text={"SPIN"} />
                </div>
                <div style={{display: 'flex', height: '100%', width: '30%'}}>
                    <TextBox width={'100%'} fontSize={"x-large"} textAlign={"right"} text={"Right"} />
                </div>

            </div>
            <div style={{height: '12px'}}/>
            <div id="TouchSlider" style={{width: '92%', height: '25%', ...TouchSliderContainer}}
            onMouseDown={UpdateSliderPos}
            onTouchStart={UpdateSliderPos}
            onTouchMove={UpdateSliderPos} 
            >
                <div style={{...TouchSliderInternals}}>
                    <div style={{...TouchSliderGlow}} />
                    <div style={{display: 'flex', height: '100%', width: '10%'}}>
                        <TextBox width={'100%'} fontSize={"x-large"} textAlign={"left"} text={"<"} textStyle={{color: 'white', textShadow: '0px 1px 2px rgba(0,0,0, .5)'}} />
                    </div>

                    <div style={{display: 'flex', height: '100%', width: '20%'}}>
                        <TextBox width={'100%'} fontSize={"x-large"} textAlign={"center"} text={"-"} textStyle={{color: 'white', textShadow: '0px 1px 2px rgba(0,0,0, .5)'}} />
                    </div>

                    <div style={{display: 'flex', height: '100%', width: '10%'}}>
                        <TextBox width={'100%'} fontSize={"x-large"} textAlign={"right"} text={">"} textStyle={{color: 'white', textShadow: '0px 1px 2px rgba(0,0,0, .5)'}} />
                    </div>
                    
                    <TouchSliderMarker left={SliderPos} />


                 </div>
                 
            </div>
            
            
        </div>
    );
}

export function TouchSliderVerticalLine(props){
    const {height} = props;


    return(
        <div style={{display: 'flex', width: '2px', height: height, backgroundColor: 'black', alignSelf: 'center', pointerEvents: 'none'}} />
    );
}

export function TouchSliderMarker(props){
    const {left} = props;

    const SliderMarker = {
        position: 'absolute',
        width: '36px',
        height: '36px',
        top: 'calc(50% - 18px)',
        outline:  '4px solid white',
        background: `linear-gradient(to bottom, #FFD600 37.5%, ${'orange'})`,
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5), inset 2px 2px 4px rgba(0, 0, 0, 0.125)',
        borderRadius: '50%'
    }

    return(
        <div style={{left: `calc(${left}% - 18px)`, pointerEvents: 'none', ...SliderMarker}} />
    );
}