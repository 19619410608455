import React, { useContext, useMemo } from "react";
import Context from "../context/Context";
import "../Styles/FigmaStyle.css";
import { GetPlayerIcon } from "../Sprites/PlayerIconLoader.js";


export function PageBackground(props){
    const {bkgColor ,bkgImage, useBkgGradient, useBlur, Logo, useLogo, useSmallLogo, bkgStyle } = props;
    
    const backgroundImage = useMemo(() => { return  bkgImage ?? ''}, [bkgImage])
    const pageLogo = useMemo(() => { return  Logo}, [Logo])

    const bkgBackboard = {
        position: "fixed",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        backgroundColor: bkgColor ?? 'black',

    }
    
    const bkgBlurOverlay = {
        position: "fixed",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        backgroundColor: 'black',
        filter: useBlur ? 'blur(.375rem)' : ''   
    }

    const bkg = {
        position: "inherit",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",  
        backgroundColor: bkgColor ?? 'white'

    }

    const bkgGradientRepeat = {
        position: "inherit",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        background: useBkgGradient ? "repeating-linear-gradient(40deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .75), rgba(0, 0, 0, 0), rgba(0, 0, 0, .75))" : ''
    }

    const bkgGradientLinear = {
        position: "inherit",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        background: useBkgGradient ? "linear-gradient(to right, rgba(0, 0, 0, .1) 25%, rgba(0,0,0,0))" : ''
    }

    const CenteredLogo = {
        position: "inherit",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        background: `center no-repeat url(${pageLogo})`, 
        filter: "drop-shadow(12px 12px 8px black)"
    }

    const SmallLogo = {
        position: "inherit",
        top: "7.5%",
        height: "25%",
        width: "100%",
        background: `center/50% no-repeat url(${pageLogo})`, 
        filter: "drop-shadow(6px 6px 4px black)",
    }

    const BlackOverlay = {
        position: "fixed",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        backgroundColor: 'rgba(25,25, 25, .825)',
    }

    return(
        <div style={{...bkgBackboard}}>
            <div style={{...bkgBlurOverlay}}>
                <div id="PageBackground" style={{background: `url(${backgroundImage}) center`, ...bkg, ...bkgStyle }} />
                <div id="PageGradient1" style={{...bkgGradientRepeat }}>          
                    <div id="PageGradient2" style={{...bkgGradientLinear }}>
                        {
                            useLogo ?
                            (!useSmallLogo ? <div id="PageLogo" style={{...CenteredLogo }} /> : <div id="PageLogo" style={{...SmallLogo }} />) : <></>
                        }
                    </div>
                    {useBlur ? <div style={{...BlackOverlay}}/> : <></>}
                </div>
            </div>
        </div>
    );
}

export function PlayerBanner(props){
    const {myColor } = props;

    const bkg = {
        position: "fixed",
        top: "0",
        left: "0",
        height: "15%",
        width: "100%",
        backgroundColor: "black",
        boxShadow: "0px 4px 48px 6px rgba(0,0,0, .75)"
    
    }

    const banner = {
        position: "absolute",
        display: "flex",
        flexFlow: "row no-wrap",
        gap: " 12px",
        justifyContent: "flex-start",
        bottom: "5%",
        left: "12px",
        height: "50%",
        width: "100%",
    }

    return(
        <div id="PlayerBannerBackground" style={{...bkg }}>
            <div id="PlayerBanner" style={{...banner }}>
                <PlayerIconBanner size={40} borderSize={2} myColor={myColor} />
                <PlayerNameBanner size={"75%" } textAlign={"left"}/>

            </div>

        </div>
    );
}

function PlayerIconBanner(props)
{
    const {size, borderSize, myColor} = props;
    const context = useContext(Context);

    const bkg = {
        position: "relative",
        height: `${size}px`,
        width: `${size}px`,
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "0px 2px 2px 2px rgba(0,0,0, .25)",
        margin: "auto auto",
        outline: `solid ${borderSize}px white`
    }

    const pIcon = {
        position: "relative",
        height: '100%',
        aspectRatio: "1/1", 
        borderRadius: "8px",
        boxShadow: "inset 2px 2px 12px 2px rgba(0,0,0, .1)",
        backgroundSize: "100% auto",
        backgroundColor: `${myColor}`
    
    }

    const iconImg = useMemo(() => { return GetPlayerIcon(context.user.playerIcon)}, [context.user.playerIcon])

    return(
        <div id="PlayerBannerIconBox" style={{...bkg }}>
            {iconImg != null ? <img id="PlayerIcon" src={iconImg ?? ''} style={{...pIcon}} /> : <></>}
        </div>
    );
}

 function PlayerNameBanner(props)
{
    const {size, textAlign} = props;
    const context = useContext(Context);

    const bkg = {
        position: "relative",
        height: "100%",
        width: `${size}`,
        display: 'flex',
        justifyContent: 'center',
    }

    const pName = {
        position: 'relative',
        width: '100%',
        margin: "auto auto",
        textAlign: `${textAlign}`,
        color: 'white',
        fontSize: 'xx-large',
        overflow: "hidden",
        textOverflow: 'ellipsis',
        whiteSpace: "nowrap",
        fontFamily: 'Rajdhani'
    }

    const playerName = useMemo(() => { return  context.user.name ?? 'Unknown Player'}, [context.user.name])

    return(
        <div id="PlayerNamenBox" style={{...bkg }}>
            <span id="PlayerName" style={{...pName}} >{playerName}</span>
        </div>
    );
}

export function PlayerIcon(props)
{
    const context = useContext(Context);
    const {size, borderSize,  myColor} = props;

    const bkg = {
        position: 'absolute',
        top: '0',
        left: '0',
        height: size ?? '100%',
        width: size ?? '100%',
        backgroundColor: "white",
        borderRadius: "16px",
        boxShadow: "0px 4px 2px 2px rgba(0,0,0, .5)",
        outline: `solid ${borderSize}px white`
    }

    const pIcon = {
        position: "relative",
        margin: "auto auto",
        height: '100%',
        aspectRatio: "1/1", 
        borderRadius: "16px",
        boxShadow: "inset 2px 2px 12px 2px rgba(0,0,0, .1)",
        backgroundSize: "100% auto",
        backgroundColor: `${myColor}`
    
    }

    const iconImg = useMemo(() => {return GetPlayerIcon(context.user.playerIcon)}, [context.user.playerIcon])

    return(
        <div id="PlayerIconBox" style={{...bkg }}>
            {iconImg != null ? <img id="PlayerIcon" src={iconImg ?? ''} style={{...pIcon}} /> : <></>}
        </div>
    );
}

 export function TextBox(props)
{
    const {text, width, textAlign, fontSize, bkgStyle, textStyle, textWrap, selected} = props;

    const bStyle = {
        display: 'flex',
        position: 'relative',
        height: "100%",
        width: `${width}`,
        ...bkgStyle
    }

    const tStyle = {
        position: 'relative',
        width: '100%',
        margin: "auto auto",
        textAlign: textAlign ?? 'left',
        lineHeight: '100%',
        color: 'white',
        fontSize: fontSize ?? 'xx-large',
        overflow: !textWrap ? "hidden" : 'wrap',
        textOverflow: 'ellipsis',
        whiteSpace: !textWrap ? "nowrap" : 'wrap',
        fontFamily: 'Rajdhani',
        ...textStyle
    }

    return(
        <div id="TextBox" style={{...bStyle}}>
            <span id="TextBoxText" style={{...tStyle}} >{text}</span>
        </div>
    );
}
