import React, { useContext, useMemo } from "react";
import Context from "../context/Context.js";
import "../Styles/FigmaStyle.css";
import { SendSpecificMessageToServer } from  "../helpers/NetworkMessaging";
import { GetTeamIcon   } from "../Sprites/PlayerIconLoader.js";
import { TextBox } from "./PagePrefabs.js";


export function TeamSelection(props) {
const {TeamContext, smts, GameState} = props

return(
        <>
        <div style={{position: "absolute", width: "100%", height: "85%", top: "15%"}}>

            <div style={{position: "relative", width: "90%", height: "100%", margin: '0 auto', display: 'flex', flexFlow: "column nowrap", justifyContent:'flex-start', gap: '12px'}}>
                <div style={{position: 'relative', width: '100%', height: "80px", display: 'flex', margin: '0 auto'}}>
                    <TextBox text={"SELECT YOUR TEAM"} width={"100%"} textAlign={"center"} fontSize={'xx-large'} textStyle={{color: 'white'}}/>
                </div>
                <div style={{position: 'relative', width: "100%", height: "80px", display: 'flex', margin: '0 auto'}}>
                    <TeamSelectionButton onClickButton={() => TeamContext.SelectTeamToPlay("Home")} Selected={TeamContext.Teams.myteam.color} Team={TeamContext.Teams.hometeam} />
                </div>
                <div style={{position: 'relative', width: "100%", height: "80px", display: 'flex', margin: '0 auto'}}>
                    <TeamSelectionButton onClickButton={() => TeamContext.SelectTeamToPlay("Away")} Selected={TeamContext.Teams.myteam.color} Team={TeamContext.Teams.awayteam} />
                </div>
                <div style={{position: 'relative', width: "100%", height: "80px", display: 'flex', margin: '0 auto'}}>
                    <TeamSelectionButton onClickButton={() => TeamContext.SelectTeamToPlay("Neutral")} Selected={TeamContext.Teams.myteam.color} Team={TeamContext.Teams.neutralteam} />
                </div>                
            </div>
            {
                GameState.canStart ? 
                
                <div style={{position: 'absolute', bottom: '15%', left: '10%', width: "80%", height: "60px", margin: '0 auto'}}>
                    <StartGameButton onClickButton={() => smts("RequestToStartGame")} />
                </div> : 
                <div style={{position: 'absolute', bottom: '10%', left: '5%', width: "90%", height: "100px", margin: '0 auto'}}>
                    <div style={{width: '100%', height: '35%'}}>
                        <TextBox width={'100%'} text={"WATING FOR PLAYERS"} fontSize={'xx-large'} textAlign={'center'} textStyle={{textShadow: '0px 2px 2px rgba(0,0,0, .5)'}} />  
                    </div>
                    <div style={{width: '100%', height: '35%'}}>
                        <TextBox width={'100%'} text={"2 / 2"} fontSize={'xx-large'} textAlign={'center'} textStyle={{fontFamily: 'Rajdhani-Bold', color: '#FFD600', textShadow: '0px 1px 2px rgba(0,0,0, .5)'}} />
                    </div>
                    
                    
                      
                </div>
            }
            
        </div>
        </>
    );
}

function TeamSelectionButton(props){
    const{ Team, onClickButton, Selected} = props;

    const DarkColor = useMemo(() => {return `${Team.color + "00" }`}, [Team]);
    const ButtonSelected = useMemo(() => {return Team.color === Selected}, [Team, Selected]);
    
    const selectionBox = {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '100%',
        background: `linear-gradient(to right, ${Team.color} 10%, ${!ButtonSelected ? DarkColor : Team.color} 90%)`,
        border: 'inset 1px solid rgba(0,0,0, .5)',
        outline: !ButtonSelected ? '' : 'outset 3px #FFD600' ,
        outlineOffset: '2px',
        boxShadow: ButtonSelected ? "inset 0px 0px 16px 8px rgba(200,200,200, .1)" : "0px 2px 2px 2px rgba(0,0,0, .375)" ,
        borderRadius: '8px'

    }

    return(
    <div onClick={onClickButton} style={{...selectionBox}}>
        <TeamIcon size={96} name={Team.name} />
        {
            <TextBox width={'100%'} text={Team.name.toUpperCase()} textAlign={'right'} textStyle={{marginRight: '20px', textShadow: ButtonSelected  ? '2px 2px 2px black': ''}} />
        }
        

    </div>);
}

export function TeamSelectionBanner(props){
    const{ Team } = props;

    const DarkColor = useMemo(() => {return `${Team.color + 75 }`}, [Team]);
    
    const banner = {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '48px',
        textAlign: 'center',
        background: `linear-gradient(to right, ${Team.color} 65%, ${DarkColor})`,
        overflow: 'hidden'
    }

    return(
        <div style={{...banner}}>
            <TeamIcon size={200} name={Team.name} largeIcon blur={'.125rem'} opacity={'15%'}  />
            {
                <TextBox width={'100%'} text={Team.name.toUpperCase()} textAlign={'right'} textStyle={{marginRight: '20px', textShadow: '2px 2px 2px black'}} />
            }
            
    
        </div>);
}

export function TeamIcon(props)
{
    const {size, name, largeIcon, blur, opacity} = props;

    const MyIcon = useMemo(() => {return GetTeamIcon(name); }, [name]);

    const container ={
    display: 'flex', 
    flex: 'row nowrap',
    flexShrink: '0',
    justifyContent: 'center',
    height: size +'px' ?? '100%',
    width: size +'px' ?? '100%',
    marginTop: largeIcon ? `calc(-${size * .375}px )` : '-8px',
    marginLeft: largeIcon ? `calc(-${size * .25}px )` : '-4px',
    }

    const bkg = {
        display: 'flex',
        position: 'relative',
        height: '100%',
        width: '100%',
        aspectRatio: "1/1", 
        filter: (blur ? `blur(${blur})` : '') + (opacity ? `opacity(${opacity})` : '')
           
    }

    return(
        <div style={{...container}}>
        {MyIcon != null ? <img id="TeamIcon" src={MyIcon ?? ''} style={{...bkg}} /> : <></>}
        </div>
        
    );
}

function StartGameButton(props){
    const{onClickButton} = props;
   
    const selectionBox = {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '100%',
        background: `linear-gradient(to bottom, ${'yellow'} 37.5%, ${'orange'})`,
        border: 'inset 1px solid rgba(0,0,0, .5)',
        boxShadow: "0px 2px 2px 2px rgba(0,0,0, .375)",
        borderRadius: '8px'

    }

    return(
    <div onClick={onClickButton} style={{...selectionBox}}>
        <TextBox width={'100%'} text={"START GAME"} fontSize={'x-large'} textAlign={'center'} textStyle={{color: 'black', textShadow: '0px 1px 2px rgba(0,0,0, .25)'}} />   
    </div>);
}