import React, { useContext, useState } from "react";
import Context from "../context/Context";
import { popUpBox, nameEntryBox, acceptNameChangeButton, cancelNameChangeButton } from "../Sprites/ImagesLoader.js";
import "../Styles/FigmaStyle.css";




export default function InputName(props){
    const context = useContext(Context);
    const[inputText, setInputText] = useState("");

    function handleChange(event){
        setInputText(event.target.value); 
    }
    function SubmitName(){
        if(inputText !== ''){
            context.set(`playerName`, inputText);
            localStorage.setItem('PlayerName', inputText);
            //SendUpdatedName(context.user);
            props.closeMenu();
        }else{
            //Please enter a valid name
            console.log("Please enter a valid name");
            alert("Please enter a valid name");
        }
        
    }
    function CloseWindow(){
        props.closeMenu();
    }

    
    return(
        <div>
                 <div>
                    <div className="nameChangeOverlay_tintBlue"></div>
                    <div className="nameChangeOverlay_tintBlack"></div>
                    
                </div>
                <div className="popupBoxContainer">
                    <img src={popUpBox} className= "popUpBox"></img>
                    <span className="EnterNameText_InPopup">Please enter your name</span>
                    <div onClick={SubmitName}>
                        <img src ={acceptNameChangeButton} className={"EditNameButtonImageAccept_InPopup"}/>
                        <span className="EditTextNameOnButtonAccept_InPopup">Accept</span>
                    </div>
                    <div onClick={CloseWindow}>
                        <img src ={cancelNameChangeButton} className={"EditNameButtonImageCancel_InPopup"}/>
                        <span className="EditTextNameOnButtonCancel_InPopup">Cancel</span>
                    </div>
                    <div className="nameEntryContainer">
                         <img src={nameEntryBox} className="nameEntryBox2"></img>
                         <input type={"text"} className="inputClass_InPopup2" value={inputText} onChange={handleChange}/>
                    </div>
                   
                </div>

            </div>

       
    );
    
    
}


/*
 <div>
                <input type="text" value={inputText} onChange={handleChange} />
                <button onClick={SubmitName}>Update Name</button>
            
        </div>



*/
/*
export default class InputName extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          inputText: ""
        }
      }

      handleChange = (event) =>{
          this.setState({inputText: event.target.value}, () =>{
            console.log(this.state.inputText);

          });
      }
      SubmitName = () =>{
        SendUpdatedName(this.state.inputText);
    }

    render(){
        return(
            <div>
                <input type="text" value={this.state.inputText} onChange={this.handleChange} />
                <button onClick={this.SubmitName}>Update Name</button>

            </div>
        );
    }
    
}
*/