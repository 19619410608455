import Context from "../context/Context";
import websocket from "./WebSocket";

const server = 'ford-rideshare.ue.r.appspot.com';
const host = window.location.hostname.includes(server) ? server : 'localhost';
const protocol = window.location.protocol;
const port = window.location.hostname.includes(server) ? '' : ':8080';

const url = protocol + '//' + host + port;

 function _GetUserData (userid){

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    //**********CHANGE THE LOCAL HOST TO ADDRESS FOR ACCESS TO SERVER NOT LOCALLY LAUNCHED**************//
    //   /GetUserData?userid=4D3MXmFoT1Ty7F0Y0BENg68c8eh1`
    ///GetUserData?userid=4D3MXmFoT1Ty7F0Y0BENg68c8eh1`
    //console.log(ws);
    fetch(`${url}/GetUserData?userid=${userid}`, requestOptions)
    .then((response) => {
        //This is the response callback from the fetch request
        //console.log(response);
        var responsetext = response.text();
        //console.log(response.json());
        console.log(response);
        //console.log(responsetext);
        return responsetext;
    })
    .then((result) => {
        //This is the result of the return of the above reponse function. do bits with the JSON file here then do what you need to do. 
        console.log(result);
    })
    .catch(error => console.log('error', error));
    //console.log(this.state.response + "     GatewayData:POST FETCH");

console.log("Reaching the function");
}

export const GetUserData = (userid) => _GetUserData(userid);

