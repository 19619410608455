import React, { useEffect, useContext, useState, useMemo } from "react";  
import websocket from "../helpers/WebSocket";
import { useNavigate  } from "react-router-dom";
import Context from "../context/Context";
import {useNetworkingHooks} from "../helpers/NetworkMessaging";
import "../Styles/TinyGolfStyle.css";
import TouchFingerTracker from "../Components/TouchFingerTracker";


import { hockeyBkgSplash } from "../Sprites/ImagesLoader";
import ControllerHeader from "../Components/ControllerHeader";
import {HockeyPuckButton, HockeyTeamButton} from "../Components/HockeyButtons";
import TinygolfButton from "../Components/TinygolfButtonPrefab";


export default function SlotCar(props){
    const navigate = useNavigate();
    const context = useContext(Context);
    const {SendSpecificMessageToServer, JoinWaitingRoom, Disconnect} = useNetworkingHooks(context.user);
    const [JoinedGame, setJoinedGame] = useState(false);
    const [Invert, setInvert]= useState(false);
    const [playerTurn, setPlayerTurn] = useState('');
    const [myTeamColor, setTeamColor] = useState('');
    const [homeTeam, setHomeTeam] = useState({color: 'Red', name: 'Red'});
    const [awayTeam, setAwayTeam] = useState({color: 'Blue', name: 'Blue'});
    const [myCurrentPlayer, setMyCurrentPlayer] = useState('');

    const [numPucks, setNumPucks] = useState(5);

    const [selectedPuck, setSelectedPuck] = useState(0);
    
    useEffect(() => {
        //OnMount Things happen here
        document.title = "Slot Car Controller";

        websocket.AddListener(onPayload);
        window.addEventListener('beforeunload', handleTabClosing);
        console.log(context.user);
        JoinWaitingRoom(context.user);
        //SendMessageToServer();
        document.addEventListener("touchmove", preventBehavior, {passive: false});

        console.log(context.user.playerID);
        _OnClick_JoinGame();
        return () =>{
            //Unmount things happen here
            websocket.RemoveListener(onPayload);
            window.removeEventListener('beforeunload', handleTabClosing);
            document.removeEventListener("touchmove", preventBehavior, {passive: false});
        }
    }, []);

    useEffect(() => {

    }, [homeTeam]);


    const handleTabClosing = () => {
        Disconnect();
    }

    function onPayload(id, payload){
        switch(id){
            case "GoToWaitingRoom":
                navigate("/");
                break;
            case "JoinedGame":
                if(payload.playerId === context.user.playerId){
                   
                    setJoinedGame(true);
                }
                break;  
            default:
                break;
        }
    }

    function _OnClick_JoinGame(){
        console.log("Join Game selected");
        SendSpecificMessageToServer("JoinSlotCarGame", context.user); 
        setJoinedGame(true);
    }
    function _SendAccelerateCommand(){
        console.log("Accelerating");
        SendSpecificMessageToServer("Accelerating", context.user); 
    }

    function _SelectPlayer(player, index){
        setMyCurrentPlayer(player);
        
        if(player === 'P1'){
            SendSpecificMessageToServer("SelectPlayer1", context.user); 
        }else if(player === 'P2'){
            SendSpecificMessageToServer("SelectPlayer2", context.user); 
        }else if(player === 'P3'){
            SendSpecificMessageToServer("SelectPlayer3", context.user); 
        }else if(player === 'P4'){
            SendSpecificMessageToServer("SelectPlayer4", context.user); 
        }else if(player === 'P5'){
            SendSpecificMessageToServer("SelectPlayer5", context.user); 
        }

        setSelectedPuck(index);

    }

    function preventBehavior(e) {
        e.preventDefault(); 
    };

    
    return (
        <div style={{ height: '100vh', width: '100vw', background: `url(${hockeyBkgSplash})`, backgroundSize: '100% 100%'}}>  ``
            <ControllerHeader ControllerName="Slot Car"/>
            <div className="PageStartPosition">
                {JoinedGame ?
                <>
                <TouchFingerTracker/>


                </> 
                : 
                <>
                
                </>
                }
            </div>

        </div>


    );
}
