
class WS {
  constructor() {
    console.log("websocket constructor");
    this.sessionId = "";
    this.initializeWS();
    this.SuppressConsoleMessages = false;
    
    this.AllMessages = [];
    this.FunctionListeners = [];
    this.OutboundMessageQueue = [];
    
    this.AddListener = (listener) => {
      this.FunctionListeners.push(listener);
    }

    this.RemoveListener = (listener) => {
      this.FunctionListeners.splice(this.FunctionListeners.indexOf(listener), 1 );
    }

    this.Send = (data) => {
      this.WriteToConsole("Trying To Send a Message: " ,data);
      
      if (typeof data === "object") {
        data.sessionId = this.sessionId;     

        if (data.payload && typeof data.payload === "object") {    
          data.payload = JSON.stringify(data.payload);
        }
        
        data = JSON.stringify(data);
      }
      if (this.WebSocket.readyState === 1) {
        this.WriteToConsole("sending data from websocket");
        this.WebSocket.send(data);
      }else{
        this.OutboundMessageQueue.push(data);
      }
    }

    this.Close = () => {
      this.WebSocket.close();
    }
  }

  WriteToConsole = (message) => {
    if(this.SuppressConsoleMessages === false){
      console.log(message);
    }
  }

  WriteToConsole = (message, data) => {
    if(this.SuppressConsoleMessages === false){
      console.log(message, data);
    }
  }

  initializeWS = () => {
    
    const query =  new URLSearchParams(window.location.search);
    this.sessionId = query.get('sessionid');

    this.WriteToConsole("Initialize WebSocket => My SessionID: " + query.get('sessionid'));

    const server = 'innovationworks.com';
    const server2 ="192.168.50.134";
    const server3 ="127.0.0.1";
    const server4 ="10.1.10.100";
    const server5 ="10.1.10.112";
    const server6 ="192.168.50.114";
    const server9 ="192.168.50.107";

    const host = window.location.hostname.includes(server) ? server : 'localhost';    
    const protocol = window.location.protocol.includes('https') ? 'wss:' : 'ws:';
    const port = window.location.hostname.includes(server) ? ':443' : ':8080';
    const onlinePort = window.location.hostname.includes(server) ? ':49022' : ':433';

    const url = protocol + '//' + host + onlinePort;
    
    this.WriteToConsole("URL:" + url);

    this.WebSocket = new WebSocket(url);
    this.WebSocket.onopen = () => {  
      console.log("websocket open");
      this.OutboundMessageQueue.forEach(message => {
        this.Send(message);

      });
      this.OutboundMessageQueue = [];
    }

    this.WebSocket.onclose = () => {
      console.log("websocket close");
      
      setTimeout(this.initializeWS, 1000);
    }

    this.WebSocket.onmessage = (m) => {
      this.AllMessages.push(m.data);
      if (this.AllMessages.length > 30)
        this.AllMessages = this.AllMessages.slice(this.AllMessages.length - 30, this.AllMessages.length);
            
      var msg = JSON.parse(m.data);
      var payload = msg.payload ? JSON.parse(msg.payload) : null;

      if (msg !== null && this.sessionId === msg.sessionId) {
        this.FunctionListeners.forEach(element => {
          element(msg.id, payload);
        })
      }
    }
  }
}



const websocket = new WS();

export default websocket;
