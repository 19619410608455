import Context from "../context/Context";
import React, { useEffect, useContext, useState, useMemo, useRef } from "react";  
import { useNavigate, useLocation } from "react-router-dom";

import { useGameState, useTeamSelectionData } from "../helpers/TeamSelectionHooks";
import { UseNavitageToPage } from "../Components/PageNavigationElement";
import {useNetworkingHooks} from "../helpers/NetworkMessaging";
import websocket from "../helpers/WebSocket";


export function CoreGameConnectionsHooks(usercontext, gamestateContext, teamsSelectionContext){
    const navigate = useNavigate();
    const {tryNavigatePage} = UseNavitageToPage();
    const {JoinedTeam, SetTeamsSelected, CanStartGame, IsGameOver, ResetGameState, IsMyTurn} = gamestateContext;

    const CheckCoreGameConnectionMessages = (id, payload) =>{
        let isMe = IsMe(payload);
        
        console.log(`We are checking for coremessage: `, id, payload);

        switch(id){
            case "GoToWaitingRoom":
                navigate("/");
                break;

            case "JoinedGame":
                if(isMe){
                    JoinedTeam();
                }
                break;   

            case "ResponseToJoinRoomRequest":
                if(isMe){
                    JoinedTeam();
                    teamsSelectionContext.SelectTeamToPlay("Neutral");
                }
                break;
            case "ReconnectToRoomResponse":
                if(isMe){
                    console.log("Got the reconnect response");
                    JoinedTeam();
                    teamsSelectionContext.SelectTeamToPlay(payload.teamSelected);
                    if(payload.teamSelectionCompletedStatus === 'True'){
                        SetTeamsSelected(true);
                    }else{
                        SetTeamsSelected(false);
                    }
                }
                break;
            case "GameOver":
                IsGameOver(true);
                break;
            case "NotGameOver":
                IsGameOver(false);
                break;

            case "TeamSelectionCompleted":
                SetTeamsSelected(true);
                break;
            
                case "SetCurrentTurn":
                    console.log("Incoming PlayerID: " , payload.playerId, usercontext.playerId );
                    IsMyTurn(payload.playerId === usercontext.playerId)
                break;

            case "RequestCurrentSelectedTeam":
                if(isMe){
                    console.log(`Checking my currently selected team: ${teamsSelectionContext.Teams.myTeam}`);
                    teamsSelectionContext.RequestCurrentlySelectedTeam();
                }
                break;
            case "SetStartGameButtonState":
                //console.log(`SetStartGameButtonState listener called. here is the check for player: ${payload.playerId} : ${context.user.playerId}`);
                if(isMe){
                    CanStartGame(payload.readyState.toLowerCase() === 'true');

                }
                break;

            case "ResetPhoneController":
                if(isMe)
                {
                    ResetGameState();
                    teamsSelectionContext.SelectTeamToPlay("Neutral");
                }
                break;
            case "NavigateToPage":
                tryNavigatePage(payload, usercontext.playerId, websocket.sessionId);
                break;
            case "TeamStateUpdate":
                console.log("Recieved a team state update", payload);
                //receive message letting players know what teams are disabled and what are not 
                //One message that says who has control of each team, and if the teams are now disabled or still active 

                console.log("CurrentState of teambuttondisable: ", teamsSelectionContext.Teams.isawayTeamButtonDisabled, teamsSelectionContext.Teams.ishomeTeamButtonDisabled);
                teamsSelectionContext.setHomeButtonDisableState(payload.homeTeamButtonDisabled);
                teamsSelectionContext.setAwayButtonDisableState(payload.awayTeamButtonDisabled);

                break;
            default:
                break;


        }
    }

    const IsMe = (payload) => {
        console.log("Checking isMe ", payload);
        if(payload == null) return false;
        else return payload.playerId === usercontext.playerId;
    }

    return{CheckCoreGameConnectionMessages, IsMe} ;
}