import websocket from "./WebSocket";

export function useNetworkingHooks(UserContext) {

    function SendSpecificMessageToServer(Id, Payload){
    
        var msg = {
            id: Id,
            payload: Payload ?? '',
            userContext: UserContext,
        }

        websocket.Send(msg);
    }

    const JoinWaitingRoom = () => {
        SendSpecificMessageToServer("RequestToConnectToRoom");
    }

    const Disconnect = () => {
        SendSpecificMessageToServer("DiscontinueConnection");
    }

    return { SendSpecificMessageToServer, JoinWaitingRoom, Disconnect};
}


